import React from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import { useLocation } from "@reach/router";

const CookieBanner = () => {
  const location = useLocation();
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="mark-gdpr-cookies"
      style={{ background: "#2B373B" }}
      buttonStyle={{
        backgroundColor: "#415eff",
        color: "white",
        fontSize: 18,
        borderRadius: 4,
        border: "1px solid #415eff",
        marginRight: 100,
      }}
      expires={150}
      enableDeclineButton
      onDecline={() => {
        Cookies.set("mark-gdpr-google-analytics", false);
        Cookies.set("mark-gdpr-facebook-pixel", false);
        Cookies.set("mark-gdpr-google-tagmanager", false);
      }}
      onAccept={() => {
        Cookies.set("mark-gdpr-google-analytics", true);
        Cookies.set("mark-gdpr-facebook-pixel", true);
        Cookies.set("mark-gdpr-google-tagmanager", true);
        initializeAndTrack(location);
      }}
      declineButtonText="Refuse"
      declineButtonStyle={{
        backgroundColor: "navy",
        color: "lightgrey",
        borderRadius: 4,
      }}
      hideOnAccept
      // flipButtons
    >
      This website uses cookies to enhance the user experience.
    </CookieConsent>
  );
};

export default CookieBanner;
